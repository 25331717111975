<template>
  <v-container fluid class="pa-0">

    <!-- Header -->
    <Header :title="$t('headers.training.title')" :description="$t('headers.training.description')"/>

    <!-- Square buttons -->
    <SquareButtonGroup :buttons="buttons"/>

    <!-- Footer -->
    <Footer :quote="true"/>

  </v-container>
</template>

<script>
import Header from '../components/HeaderBar';
import SquareButtonGroup from '../components/SquareButtonGroup';
import Footer from '../components/Footer';

export default {
  name: 'MainTraining',
  components: {Header, SquareButtonGroup, Footer},
  data: () => ({
    buttons: [
      {icon: '$i-images', title: 'events.images', link: '/training/images'},
      {icon: '$i-words', title: 'events.words', link: '/training/words'},
      {icon: '$i-imaginary-words', title: 'events.imaginary-words', link: '/training/imaginary-words'},
      {icon: '$i-names', title: 'events.names', link: '/training/names'},
      {icon: '$i-numbers', title: 'events.numbers', link: '/training/numbers'},
      {icon: '$i-dates', title: 'events.dates', link: '/training/dates'}
    ]
  })
};
</script>
